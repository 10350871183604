import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { useState } from 'react';


function App() {
  //const paseos = axios.get('http://localhost:3000/paseos');
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(null);
  const [servers, setServers] = useState([]);
  const [visits, setVisits] = useState([]);

  function createVisitUrl(id){
    return async function(){
      const visitReq = await axios.post(`${process.env.REACT_APP_HOST}/api/create-url`, {
        userId: user,
        visitId: id,
        startTime: new Date().toISOString(),
         endTime: new Date(new Date().getTime() + 2*60000),
          webServerId: servers[0].id
      }).catch((error) => {
        console.log(error);
      });
      if (visitReq.data){

      window.open(visitReq.data.url, '_blank');
      }
    }
  }

  

  const visitsRender = visits?.map((visit, visitIndex) => {
    return (
      <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href="#">
          <img class="rounded-t-lg" src={"/paseo" + (visitIndex+1) +".jpeg"} alt="" />
      </a>
      <div class="p-5">
          <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{visit.id}</h5>
          </a>
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Piso ubicado en Barcelona, visita virtual con exteriores incluido.</p>
          <a href="#" onClick={createVisitUrl(visit.id)} class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Realizar paseo
              <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </a>
      </div>
  </div>
    )
  })

  const serversRender = servers?.map((server, serverIndex) => {
    return (
  
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    { server.id}
                </th>
                <td class="px-6 py-4">
                    { server.type }
                </td>
                <td class="px-6 py-4">
                    { server.status }
                </td>
                
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Liberar</a>
                </td>
            </tr>
 
     
    )
  })




  async function login(){
    const serversReq  = await axios.post(`${process.env.REACT_APP_HOST}/api/get-webservers`, {
      userId: user
    }).catch((error) => {
      console.log(error);
    });

    const visitsReq = await axios.post(`${process.env.REACT_APP_HOST}/api/get-visits`, {
      userId: user
    }).catch((error) => {
      console.log(error);
    });
    setIsLogged(true);
    console.log(serversReq.data)
    console.log(visitsReq.data)
    setServers(serversReq.data)
    setVisits(visitsReq.data)
  }

  const handleUserChange = (event) => {
    // 👇 Get input value from "event"
    setUser(event.target.value);
  };
  
  return (
    <div className="AppBack">
    <div className="App">

    {!isLogged &&
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-3xl font-semibold text-gray-900">
          <img class="w-8 h-8 mr-2" src="https://ugc.trycircular.com/image_79840be2-ecb3-47ed-9b46-a8eaaeb53cfb.PNG" alt="logo"/>
          Clikalia SPACE  
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Entra en tu cuenta
              </h1>
              <div class="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Usuario</label>
                      <input onChange={handleUserChange} name="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Usuario" required=""/>
                  </div>
                  <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contraseña</label>
                      <input type="password" name="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                  </div>
         
                  <button onClick={login} class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Entrar</button>
                  
              </div>
          </div>
      </div>
  </div>
}

{isLogged &&
<div>
<div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
<a href="#" class="flex items-center mb-6 text-3xl font-semibold text-gray-900">
    <img class="w-8 h-8 mr-2" src="https://ugc.trycircular.com/image_79840be2-ecb3-47ed-9b46-a8eaaeb53cfb.PNG" alt="logo"/>
    Clikalia SPACE  
</a>
</div>
<div class=" bg-white  border border-slate-400  bg-opacity-20 backdrop-blur-lg rounded-2xl drop-shadow-lg p-8 m-4">
    <h2 class="text-4xl font-extrabold text-slate-800 mb-5">Tus paseos virtuales</h2>
    <div class= "grid mb-8 border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-3">
{visitsRender}
</div>
</div>
<div class="bg-white border border-slate-400 bg-opacity-20 backdrop-blur-lg rounded-2xl drop-shadow-lg p-8 m-4" >
<h2 class="text-4xl font-extrabold text-slate-800 mb-5">Ordenadores</h2>
<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Id
                </th>
                <th scope="col" class="px-6 py-3">
                    Tipo
                </th>
                <th scope="col" class="px-6 py-3">
                    Status
                </th>
                <th scope="col" class="px-6 py-3">
                   Acciones
                </th>
            </tr>
        </thead>
        <tbody>
          { serversRender}
          </tbody>
          </table>
          </div>
          </div>
</div>


}

    </div>
    </div>

  );
}

export default App;
